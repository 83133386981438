<template>
  <div>
    <partner-slider :number="1" :slides="slider_1"></partner-slider>
    <partner-slider :number="2" :slides="slider_2"></partner-slider>
    <partner-slider :number="3" :slides="slider_3"></partner-slider>
  </div>
</template>

<script>
import PartnerSlider from  './PartnerSlider'
export default {
  components: {PartnerSlider},
  data () {
    return {
      slider_1: [],
      slider_2: [],
      slider_3: []
    }
  },
  mounted() {
    console.log('Partner Section Component mounted.');

    let biosteel = 'http://www.biosteel.com/';
    let budget = 'https://legacy.enterpriserentacar.ca/car_rental/deeplinkmap.do?bid=028&refId=PHPA';
    let pods = 'https://www.pods.com/partners/phpa?utm_medium=managed-referral&utm_source=professional_hockey_players_association&utm_campaign=phpa_referrals&utm_content=affiliate';
    let slcc = 'https://stateandliberty.com';
    let klevr = 'http://klevr.ai'

    let brainArmor = "https://brainarmor.com/";
    let sportContrac = 'https://sportcontract.net/en/';
    let isospec = 'https://isospechealth.com/';
    let osu = 'https://ecampus.oregonstate.edu/affiliate/phpa/';
    let athabasca = 'https://www.athabascau.ca/'
    let Memmo = 'https://memmo.me/global/en'
    let upperdeck = 'https://upperdeckstore.com/'
    let verbero = 'https://verbero.com/'
    let privateHousing = 'https://privatehousingnetwork.com/sports/hockey?referrer=PHPAHOUSING'
    let ofx = 'https://www.ofx.com/p/phpa/'
    let edge = 'https://vizualedge.com/phpa/';

    if (vuex.getters.hasAdminRole || vuex.getters.hasTrialAgentRole) {
      biosteel = '/membership/biosteel';
      pods = 'https://www.pods.com/partners/phpa?utm_medium=managed-referral&utm_source=professional_hockey_players_association&utm_campaign=phpa_referrals&utm_content=affiliate';
      budget = '/membership/enterprise';
      klevr = '/membership/klevr'
      slcc = 'https://stateandliberty.com';
      edge = 'https://vizualedge.com/phpa/';

      brainArmor = '/membership/brain-armor';
      sportContrac = 'https://sportcontract.net/en/';
      isospec = '/membership/isospec';
      athabasca = 'https://www.athabascau.ca/';
      Memmo = '/membership/memmo'
      verbero = '/membership/verbero'
      privateHousing = 'https://privatehousingnetwork.com/sports/hockey?referrer=PHPAHOUSING'
      ofx = 'https://www.ofx.com/p/phpa/'
      upperdeck = 'https://upperdeckstore.com/'
    }
    this.slider_1 = [
      {
        image_path: '/images/partners/biosteel.jpg',
        link: biosteel
      },
      {
        image_path: '/images/partners/enterprisenational.jpg',
        link: budget
      },
      {
        image_path: '/images/partners/Verbero.png',
        link: verbero
      },
      {
        image_path: '/images/partners/Vizual-Edge-Logo.jpeg',
        link: edge
      },

    ];
    this.slider_2 = [
      {
        image_path: '/images/partners/Klevr.jpg',
        link: klevr
      },
      {
        image_path: '/images/partners/pods.jpeg',
        link: pods
      },
      {
        image_path: '/images/partners/isospec.jpg',
        link: isospec
      },
      {
        image_path: '/images/partners/Private-Housing.jpg',
        link: privateHousing
      },{
        image_path: '/images/partners/OFX.jpg',
        link: ofx
      },
    ];
    this.slider_3 = [
      {
        image_path: '/images/partners/Athabasca-University.jpg',
        link: athabasca
      },
      {
        image_path: '/images/partners/SportContract.png',
        link: sportContrac
      },
      {
        image_path: '/images/partners/osu.jpg',
        link: osu
      },
      {
        image_path: '/images/partners/slcc.jpg',
        link: slcc
      },
      {
        image_path: '/images/partners/Upper-Deck.jpeg',
        link: upperdeck
      },
    ];
  }
}
</script>
